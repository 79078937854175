<template>
  <div class="app-card">
    <!-- search start -->
    <a-form layout="inline" :form="searchForm">
      <a-form-item label='名称'>
        <a-input placeholder="名称" v-model="searchForm.Name" />
      </a-form-item>
      <a-button-group style="line-height: 39px;">
        <a-button icon="delete" type="danger" @click="cleanSearchClick">清除查询</a-button>
        <a-button icon="search" type="primary" @click="searchClick">查询</a-button>
      </a-button-group>
    </a-form>
    <div style="padding-top: 15px;padding-bottom: 15px;" v-if="$root.$data.privateState.action.includes('/Role/Create') || $root.$data.privateState.role === 'SuperAdmin'">
      <a-button icon="plus" type="primary" @click="addRoleClick">添加角色</a-button>
    </div>
    
    <!-- search end -->
    <!-- table start -->
    <a-table
      :columns="columns"
      :rowKey="record => record.Uuid"
      :dataSource="tableData"
      :pagination="pagination"
      :loading="loading"
      :scroll="{ x: 750 }"
      @change="handleTableChange"
      bordered
    >
      <template #AuthNames="AuthNames">
        <a-tag v-for="it in AuthNames" :key="it">{{it}}</a-tag>
      </template>
      <template #role="role">
        <a-tag :color="$statusEnum.userRole[role].color">{{$statusEnum.userRole[role].text}}</a-tag>
      </template>
      <template #action="data">
        <div v-if="data.Uuid !== 'Admin'">
          <a href="javascript:;" @click="editRoleClick(data)">编辑</a> | 
          <a-popconfirm
            title="是否确认删除该角色?"
            @confirm="deleteRoleClick(data)"
            okText="是"
            cancelText="否"
          >
            <a href="javascript:;">删除</a>
          </a-popconfirm>
        </div>
      </template>
    </a-table>
    <a-modal width="95%" :title="createAndEditModalTitle" v-model="createAndEditShow" @ok="createAndEditModalOk">
      <a-form layout="inline" :form="createAndEditForm">
        <a-form-item label='角色名称'>
          <a-input-group compact>
            <a-input placeholder="角色名称" v-decorator="['name', {rules: [{ required: true, message: '请输入' }]}]"/>
            <a-checkbox :indeterminate="indeterminate" :checked="checkAll" @change="onCheckAllOfAllChange" >全选</a-checkbox>
          </a-input-group>
          
        </a-form-item>
        <div v-for="it in authTree" :key="it.name">
          <a-divider orientation="left">{{it.name}}<a-divider type="vertical"/><a-checkbox :indeterminate="it.indeterminate" :checked="it.checkAll" @change="e => onCheckAllChange(e, it)" >全选</a-checkbox></a-divider>
          <a-checkbox-group :options="it.data" v-model="it.selected" @change="checkedValues => onChangeSelectRole(checkedValues, it)"/>
        </div>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import licia from 'licia'
import { Role } from '@/api'
import { aliveVM, mixinTableList } from '@/utils/mixin'

const INIT_SEARCH_FORM = '{"Name":""}'

export default {
  name: 'SystemRole',
  mixins: [aliveVM, mixinTableList],
  created () {
    this.fetch()
  },
  async mounted () {
    await this.initAuthsSelect()
  },
  data () {
    return {
      tableListApi: `${Role}/TableList`,
      searchParams: null,
      searchForm: JSON.parse(INIT_SEARCH_FORM),
      columns: [
        {
          title: '名称',
          dataIndex: 'Name'
        },
        {
          title: '权限',
          dataIndex: 'AuthNames',
          scopedSlots: { customRender: 'AuthNames' },
        },
        {
          title: '操作',
          key: 'operation',
          width: 230,
          scopedSlots: { customRender: 'action' },
        }
      ],
      authTree: [],
      createAndEditModalTitle: '添加角色',
      createAndEditShow: false,
      createAndEditForm: this.$form.createForm(this),
      nowFormType: '',
      nowEditUuid: '',
      organizationTree: [],
      indeterminate: false,
      checkAll: false,

    }
  },
  methods: {
    async initAuthsSelect() {
      const apiRes = await this.$axios.get(`${Role}/GetAuths`)
        .catch(e => {
          return {
            status: false,
            message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
          }
        })
      if (!apiRes.status) {
        this.$message.error(apiRes.message)
        return
      }
      const authTreeKV = {}
      for (const it of apiRes.data) {
        if (!authTreeKV[it.controllerShowName]) {
          authTreeKV[it.controllerShowName] = [
            { value: it.path, label: it.actionShowName }
          ]
          continue;
        }
        authTreeKV[it.controllerShowName].push({ value: it.path, label: it.actionShowName })
      }
      const authTree = [];
      for (const key in authTreeKV) {
        authTree.push({
          name: key,
          data: authTreeKV[key],
          checkAll: false,
          indeterminate: false,
          selected: [],
        })
      }
      this.$set(this, 'authTree', authTree)
    },
    async reload() {
      this.$set(this, 'searchParams', null)
      this.searchForm.resetFields()
      await this.fetch()
    },
    async cleanSearchClick () {
      this.$set(this, 'searchParams', null)
      this.$set(this, 'searchForm', JSON.parse(INIT_SEARCH_FORM))
      await this.fetch()
    },
    async searchClick () {
      const searchForm = JSON.parse(JSON.stringify(this.searchForm))
      if (searchForm.Status !== '') {
        searchForm.Status = parseInt(searchForm.Status)
      }
      const searchParams = {}
      for (const k of Object.keys(searchForm)) {
        const it = searchForm[k]
        if (licia.isNum(it)) {
          searchParams[k] = it
          continue
        }
        if (!licia.isEmpty(it)) {
          searchParams[k] = it
        }
      }
      this.$set(this, 'searchParams', searchParams)
      this.fetch()
    },
    onChangeCreateAndEditSelectOrg (value) {
      this.createAndEditForm.setFieldsValue({
        organizationUuid: value
      })
    },
    onChangeCreateAndEditSelectRole () {
      this.$set(this.formData, 'actionOrganization', [])
      this.$set(this.formData, 'authAction', [])
      this.createAndEditForm.setFieldsValue({
        actionOrganization: [],
        authAction: []
      })
    },
    addRoleClick () {
      const formData = {
        name: '',
        username: '',
        password: '',
        phone: '',
        role: '',
        organizationUuid: [],
        remarks: ''
      }
      const authTree = this.authTree;
      for (const it of authTree) {
        const selected = []
        it.selected = selected
        it.indeterminate = false
        it.checkAll = false
      }
      this.indeterminate = false
      this.checkAll = false
      this.$set(this, 'authTree', authTree)
      this.createAndEditModalTitle = '添加角色'
      this.$set(this, 'formData', formData)
      this.$set(this, 'nowFormType', 'create')
      this.createAndEditForm.resetFields()
      this.createAndEditShow = true
    },
    onCheckAllOfAllChange (e) {
      if (e.target.checked) {
        for (const it of this.authTree) {
          var selected = []
          for (const jt of it.data) {
            selected.push(jt.value)
          }
          Object.assign(it, {
            selected: selected,
            indeterminate: false,
            checkAll: true,
          });
        }
      } else {
        for (const it of this.authTree) {
          Object.assign(it, {
            selected: [],
            indeterminate: false,
            checkAll: false,
          });
        }
      }
    },
    onChildCheckChange() {
      let status = 2; // 0-null 1-some 2-all
      let hasSome = false;
      let hasNoSelect = false;
      for (const it of this.authTree) {
        if (it.selected.length > 0) {
          hasSome = true
          if (it.selected.length != it.data.length) {
            status = 1
            hasNoSelect = true
            break;
          }
        } else {
          hasNoSelect = true
        }
      }
      if (!hasSome) {
        status = 0
      } else if (hasNoSelect) {
        status = 1
      }
      switch (status) {
        case 0:
          this.indeterminate = false
          this.checkAll = false
          break;
        case 1:
          this.indeterminate = true
          this.checkAll = false
          break;
        case 2:
          this.indeterminate = false
          this.checkAll = true
          break;
        default:
          break;
      }
    },
    onCheckAllChange (e, it) {
      let selected = []
      if (e.target.checked) {
        for (const it of it.data) {
          selected.push(it.value)
        }
      } else {
        selected = []
      }
      Object.assign(it, {
        selected: selected,
        indeterminate: false,
        checkAll: e.target.checked,
      });
      this.onChildCheckChange()
    },
    onChangeSelectRole (checkedValues, it) {
      if (it.data.length == checkedValues.length) {
        it.indeterminate = false;
        it.checkAll = true;
      } else if (checkedValues.length > 0) {
        it.indeterminate = true;
        it.checkAll = false;
      } else {
        it.indeterminate = false;
        it.checkAll = false;
      }
      this.onChildCheckChange()
    },
    createAndEditModalOk () {
      let selectedAuth = []
      for (const it of this.authTree) {
        selectedAuth = [ ...selectedAuth, ...it.selected ]
      }
      if (selectedAuth.length < 1) {
        this.$message.error('请至少选择一个权限')
        return
      }
      this.createAndEditForm.validateFieldsAndScroll(async (err, values) => {
        if (err) {
          this.$message.error('请检查填写内容')
          return
        }
        if (this.nowFormType === 'create') {
          const apiRes = await this.$axios.post(`${Role}/Create`, {
            name: values.name,
            authPath: selectedAuth
          })
            .catch(e => {
              return {
                status: false,
                message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
              }
            })
          if (!apiRes.status) {
            this.$message.error(apiRes.message)
            return
          }
          this.createAndEditShow = false
          this.$message.success('新建角色成功')
          this.fetch()
        } else if (this.nowFormType === 'edit') {
          const apiRes = await this.$axios.put(`${Role}/Edit?uuid=${this.nowEditUuid}`, {
            name: values.name,
            authPath: selectedAuth
          })
            .catch(e => {
              return {
                status: false,
                message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
              }
            })
          if (!apiRes.status) {
            this.$message.error(apiRes.message)
            return
          }
          this.createAndEditShow = false
          this.$message.success('编辑角色成功')
          this.fetch()
        }
      })
    },
    editRoleClick (data) {
      this.nowEditUuid = data.Uuid
      this.createAndEditModalTitle = '编辑角色'
      this.$set(this, 'nowFormType', 'edit')
      this.createAndEditForm.resetFields()
      const authTree = this.authTree;
      for (const it of authTree) {
        const selected = []
        for (const ii of it.data) {
          if (data.AuthPath.includes(ii.value)) {
            selected.push(ii.value)
          }
        }
        it.selected = selected
      }
      this.$set(this, 'authTree', authTree)
      this.createAndEditForm.getFieldDecorator('name', {rules: [{ required: true, message: '请输入' }]})
      this.createAndEditForm.setFieldsValue({
        name: data.Name,
        
      })
      this.createAndEditShow = true
    },
    async deleteRoleClick (data, force = 'no') {
      const apiRes = await this.$axios.delete(`${Role}/Delete?uuid=${data.Uuid}&force=${force}`)
        .catch(e => {
          return {
            status: false,
            message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
          }
        })
      if (!apiRes.status) {
        if (apiRes.data) {
          const _this = this;
          this.$confirm({
            title: apiRes.message,
            content: apiRes.data.join(";"),
            okText: '强制删除',
            onOk() {
              _this.deleteRoleClick(data, 'yes')
            },
            onCancel() {},
          });
          return;
        }
        this.$message.error(apiRes.message)
        return
      }
      this.fetch()
    }
  }
}
</script>

<style>

</style>